@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');


/**
 * College Board css variable defaults
 */
:root {
  --content-builder-ui-tint-color: #324DC7;
  --content-builder-ui-font: inherit;
  
  --content-builder-content-min-canvas-height: 716px;

  /* Color and fonts for the content canvas */
  --content-builder-canvas-tint-color: #324DC7;
  --content-builder-canvas-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  /* Highlight / Selection color */
  --content-builder-highlight-color: #0085FF;

  /** Text */
  --content-builder-text-color: #333333;
  --content-builder-text-description-color: #333333;
  --content-builder-text-value-color: #767676;

  /* Base Colors */
  --content-builder-color-red: red; 
  --content-builder-color-orange: #F56A00;
  --content-builder-color-green: #45aa2b;
  --content-builder-color-icon-grey: #838383;
  --content-builder-color-background: #F6F6F6;
}
